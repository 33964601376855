import { isValidElement, useMemo } from "react";
import type { ReactNode } from "react";
import type { TippyProps } from "@tippyjs/react";
import Tippy from "@tippyjs/react";
import type { FrameContextProps } from "react-frame-component";
import { useFrame } from "react-frame-component";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";

interface TippyV2Props extends Omit<TippyProps, "children"> {
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  enabled?: boolean;
  shouldUseSpanWrapper?: boolean;
}

export const TippyV2 = ({
  allowHTML = true,
  arrow = true,
  children,
  className,
  content,
  dataTestId = "",
  delay = [400, 40],
  duration = [200, 200],
  enabled = true,
  interactive = true,
  interactiveBorder = 5,
  interactiveDebounce = 50,
  placement = "top",
  shouldUseSpanWrapper = true,
  appendTo,
  ...rest
}: TippyV2Props) => {
  const frame: FrameContextProps = useFrame();
  const { isInsideModal } = useModalData();
  const element = frame?.document?.documentElement;

  const appendToElement = useMemo(() => {
    if (!isInsideModal) {
      return element ?? document.body;
    }

    const headlessUIPortalRoot = document.getElementById(
      "headlessui-portal-root",
    );
    return headlessUIPortalRoot ?? element ?? document.body;
  }, [isInsideModal, element]);

  const Component = shouldUseSpanWrapper ? (
    <span className={className} data-testid={dataTestId}>
      {children}
    </span>
  ) : isValidElement(children) ? (
    children
  ) : (
    <>{children}</>
  );

  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <Tippy
      allowHTML={allowHTML}
      appendTo={appendTo ?? appendToElement}
      arrow={arrow}
      content={content}
      delay={delay}
      duration={duration}
      interactive={interactive}
      interactiveBorder={interactiveBorder}
      interactiveDebounce={interactiveDebounce}
      placement={placement}
      {...rest}
    >
      {Component}
    </Tippy>
  );
};
