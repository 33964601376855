import { flow } from "lodash";
import { useShouldShowLevelFilter } from "@circle-react-shared/MemberFilters/useShouldShowLevelFilter";
import { AllFiltersList, DynamicFilters } from "./DynamicFilters";
import { FilterValueContextProvider } from "./FilterValueContext";
import { ProfileFieldFilter } from "./ProfileFieldFilter";
import type { RenderFilter } from "./types";
import { useMemberFilterUrl } from "./useMemberFilterUrlV2";
import { usePaywallFilter } from "./usePaywallFilter";
import { useProfileFieldFilters } from "./useProfileFieldFilters";
import { useShouldShowActivityScoreFilter } from "./useShouldShowActivityScoreFilter";
import { useShouldShowAvatarSetFilter } from "./useShouldShowAvatarSetFilter";
import { useShouldShowMemberDaysAgoFilters } from "./useShouldShowDaysAgoFilter";
import { useShouldShowInvitationLinksFilter } from "./useShouldShowInvitationLinksFilter";
import { useShouldShowMarketingFilters } from "./useShouldShowMarketingFilters";
import { useShouldShowMemberTagsFilter } from "./useShouldShowMemberTagsFilter";
import { useShouldShowSegmentFilter } from "./useShouldShowSegmentFilter";

interface MemberFiltersProps {
  platformFilters: RenderFilter[];
  defaultFilterNames: string[];
}

export const MemberFilters = ({
  platformFilters = [],
  defaultFilterNames,
}: MemberFiltersProps) => {
  const { filterParams, updateUrl, removeFilterFromUrl } = useMemberFilterUrl();
  const {
    isLoadingProfileFields,
    customProfileFields,
    filteredDefaultFilters,
  } = useProfileFieldFilters({
    platformFilters,
    component: ProfileFieldFilter,
  });

  const marketingFiltered = flow(
    usePaywallFilter,
    useShouldShowMemberDaysAgoFilters,
    useShouldShowAvatarSetFilter,
    useShouldShowMemberTagsFilter,
    useShouldShowInvitationLinksFilter,
    useShouldShowLevelFilter,
    useShouldShowSegmentFilter,
    useShouldShowMarketingFilters,
  )(filteredDefaultFilters);
  const finalFilters = useShouldShowActivityScoreFilter({
    filters: marketingFiltered,
    canShowActivityScoreDisabled: true,
  });

  if (isLoadingProfileFields) {
    return null;
  }

  return (
    <FilterValueContextProvider
      filterParams={filterParams}
      onApply={updateUrl}
      onClearAll={removeFilterFromUrl}
    >
      <DynamicFilters
        defaultFilterNames={defaultFilterNames}
        platformFilters={finalFilters}
        profileFieldFilters={customProfileFields}
      >
        <DynamicFilters.Panel>
          <AllFiltersList />
        </DynamicFilters.Panel>
        <DynamicFilters.ClearButton />
      </DynamicFilters>
    </FilterValueContextProvider>
  );
};
